import { ReactElement } from "react";
import CabDatePicker, { CabDatePickerProps } from ".";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export const UserPrefCabDatePicker = ({ 
  ...props
}: CabDatePickerProps): ReactElement => {
  const weekStartPref = useSelector((root: RootState) => root.schedule.schedulingPrefs.user_prefs?.week_start_day);

  return <CabDatePicker {...props} weekStartDay={props.weekStartDay || weekStartPref}/>;
};

export default UserPrefCabDatePicker;